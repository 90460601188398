import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { sharedAuthPostKeygen, sharedAuthQueryKeygen } from 'src/core/query/sharedKeygen';
import { useGetAuthStatus } from '../../hooks/useGetAuthStatus';

interface PropsType {
  onClose: () => void;
  countryCode?: number;
  identifier: string;
  sendType: 'contact_info_mobile' | 'contact_info_email';
  verifyType: 'contact_info_sms' | 'contact_info_email';
}

type OTPStateType = 'no-code-sent-yet' | 'code-already-sent';

export const useAuthOTP = (props: PropsType) => {
  const codeInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const [nuance, setNuance] = useState<string | null>(null);
  const [maxTime, setMaxTime] = useState<number | null>(null);
  const [timerCount, setTimerCount] = useState<number>(0);

  const { mutateAsync: mutateSendIdentifiers, isLoading: isSendLoading } = useMutation(
    sharedAuthPostKeygen('/api/v1/auth/user/otp/identifiers/send'),
  );

  const { mutateAsync: mutateVerifyIdentifiers, isLoading: isVerifyLoading } = useMutation(
    sharedAuthPostKeygen('/api/v1/auth/user/otp/identifiers/verify'),
  );

  const { refetch: refetchStatus, isLoading: statusIsLoading } = useGetAuthStatus();

  const { refetch: refetchContact, isLoading: contactIsloading } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/user/investor/info/contact', {
      params: { header: {} },
    }),
  });

  const isOTPLoading = isVerifyLoading || isSendLoading || statusIsLoading || contactIsloading;

  const OTPState: OTPStateType = !nuance ? 'no-code-sent-yet' : 'code-already-sent';

  const remainingOTPTime = (maxTime ?? 0) - timerCount;

  const requestOTPCode = async (identifier: string) => {
    const response = await mutateSendIdentifiers({
      body: {
        user_identifier: identifier,
        user_type: props.sendType,
        mobile_country_code: props.countryCode,
      },
      params: {},
    });

    if (response?.data?.data) {
      setNuance(response.data.data?.temp_code);
      localStorage.setItem(`${props.sendType}_temp_code`, response.data.data?.temp_code);

      setMaxTime(Number(response.data.data?.time));

      if (codeInterval.current) clearInterval(codeInterval.current);
      codeInterval.current = setInterval(() => {
        setTimerCount((prevTimerCount) => {
          if (prevTimerCount < Number(response.data.data?.time)) {
            return prevTimerCount + 1;
          } else {
            if (codeInterval.current) clearInterval(codeInterval.current);
            setNuance(null);
            localStorage.setItem(`${props.sendType}_temp_code`, '');
            return 0;
          }
        });
      }, 1000);
    } else if (response.error?.data && localStorage.getItem(`${props.sendType}_temp_code`)) {
      const tmpNuance = localStorage.getItem(`${props.sendType}_temp_code`);
      const timer = response.error.data.remained_time;

      setNuance(tmpNuance);
      setMaxTime(Number(timer));

      if (codeInterval.current) clearInterval(codeInterval.current);
      codeInterval.current = setInterval(() => {
        setTimerCount((prevTimerCount) => {
          if (prevTimerCount < Number(timer)) {
            return prevTimerCount + 1;
          } else {
            if (codeInterval.current) clearInterval(codeInterval.current);
            setNuance(null);
            localStorage.setItem(`${props.sendType}_temp_code`, '');
            return 0;
          }
        });
      }, 1000);
    } else if (response.error?.data) {
      //@ts-ignore
      setCaptionError({ code: response.error.data.user_identifier }, setError);
    }
  };
  const verifyOTPCode = (identifier: string, verifyCode: string) => {
    mutateVerifyIdentifiers({
      body: {
        user_identifier: identifier,
        code_type: props.verifyType,
        temp_code: nuance!,
        verify_code: verifyCode,
      },
      params: {},
    })
      .then(() => refetchStatus())
      .then(() => refetchContact())
      .then(props.onClose);
  };

  const handleVerifyOTPCode = (code: string) => () => {
    if (OTPState === 'code-already-sent' && code) verifyOTPCode(props.identifier, code);
  };

  const handleRequestOTPCodeOnClick = () => {
    OTPState === 'no-code-sent-yet' && requestOTPCode(props.identifier);
  };

  return {
    handleRequestOTPCodeOnClick,
    handleVerifyOTPCode,
    isOTPLoading,
    OTPState,
    remainingOTPTime,
    maxTime,
  };
};
