import { UseFormReset, UseFormWatch } from 'react-hook-form';
import { components } from 'src/types/shared/auth';
import { ContactInformationSchemaType } from '../../schema';
import { t } from '@lingui/macro';

type PropsType = {
  status?: components['schemas']['datastruct.InvestorAuthStatusOutputs'];
  contactInfo?: components['schemas']['datastruct.GetInvestorContactInfoOutputs'];
  countryData?: components['schemas']['datastruct.CountriesInfoListOutputs'];
  provinceData?: components['schemas']['datastruct.ProvincesInfoListOutputs'];
  cityData?: components['schemas']['datastruct.CitiesInfoListOutputs'];
  watch: UseFormWatch<ContactInformationSchemaType>;
  reset: UseFormReset<ContactInformationSchemaType>;
};

type NameType = keyof Exclude<
  components['schemas']['datastruct.GetInvestorContactInfoOutputs']['data'],
  undefined
>;
type SelectValues = Exclude<
  components['schemas']['datastruct.CountriesInfoListOutputs']['data'],
  undefined
>['countries'];

export type FieldType = {
  label: string;
  placeholder: string;
  type: 'text' | 'select' | string;
  name: NameType;
  disable?: boolean;
  values?: SelectValues;
  confirmed?: boolean;
  onChange?: (value: number) => void;
  optional?: boolean;
};

export const fillInputs = (props: PropsType) => {
  const dataInputs: FieldType[] = [
    {
      label: t`Email`,
      placeholder: t`Enter your email`,
      type: 'text',
      name: 'email',
      disable:
        props.status?.data?.identifier === 'email_verified' ||
        props.status?.data?.identifier === 'full_verified',

      confirmed:
        !!props.contactInfo?.data?.email &&
        props.contactInfo?.data?.email === props.watch('email') &&
        (props.status?.data?.identifier === 'email_verified' ||
          props.status?.data?.identifier === 'full_verified'),
    },
    {
      label: t`Phone number`,
      placeholder: t`Enter your phone number`,
      type: 'text',
      name: 'mobile',
      confirmed:
        !!props.contactInfo?.data?.mobile &&
        props.contactInfo?.data?.mobile.number === props.watch('mobile.number') &&
        !!props.watch('mobile.country_code') &&
        props.contactInfo?.data?.mobile.country_code === props.watch('mobile.country_code') &&
        (props.status?.data?.identifier === 'phone_verified' ||
          props.status?.data?.identifier === 'full_verified'),
    },
    {
      label: t`Country`,
      placeholder: t`Select the desired country`,
      type: 'select',
      name: 'country_id',
      values: props.countryData?.data?.countries,
      disable: !props.countryData?.data?.countries,
      onChange: (value: number) => {
        props.reset({
          ...props.watch(),
          country_id: value,
          province_id: undefined,
          city_id: undefined,
        });
      },
    },
    {
      label: t`Province`,
      placeholder: t`Select the desired province`,
      type: 'select',
      name: 'province_id',
      values: props.provinceData?.data?.provinces,
      disable: !props.provinceData?.data?.provinces,
      onChange: (value: number) => {
        props.reset({ ...props.watch(), province_id: value, city_id: undefined });
      },
    },
    {
      label: t`City`,
      placeholder: t`Select the desired city`,
      type: 'select',
      name: 'city_id',
      values: props.cityData?.data?.cities,
      disable: !props.cityData?.data?.cities,
    },
    {
      label: t`Zip code`,
      placeholder: t`Without space and dash`,
      type: 'text',
      name: 'postal_code',
    },
  ];
  const addressInput: FieldType = {
    label: t`Address`,
    placeholder: t`Enter your address`,
    type: 'text',
    name: 'address',
  };

  return {
    dataInputs,
    addressInput,
  };
};
