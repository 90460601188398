import { useEffect, useState } from 'react';
import { components } from 'src/types/en/auth';

export const useAuthStep = (status?: components['schemas']['datastruct.UserAccountStatus']) => {
  const [formStep, setFormStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  useEffect(() => {
    switch (status) {
      case 'deactivate':
        setFormStep(0);
        setLastStep(0);
        break;
      case 'identity_docs_completed':
        setFormStep(1);
        setLastStep(1);

        break;
      case 'personal_info_completed':
        setFormStep(2);
        setLastStep(2);
        break;
      case 'contact_info_completed':
        setFormStep(3);
        setLastStep(3);
        break;
      case 'bank_info_completed':
        setFormStep(3);
        setLastStep(4);
        break;
      case 'pending':
        setFormStep(3);
        setLastStep(4);
        break;
      case 'rejected':
        setFormStep(3);
        setLastStep(4);
        break;
      case 'active':
        setFormStep(3);
        setLastStep(4);
        break;
    }
  }, [status]);

  return {
    formStep,
    lastStep,
    setFormStep,
    setLastStep,
  };
};
