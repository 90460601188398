import { useEffect, useState } from 'react';
import { MyAxios } from 'src/core/axios/requests';
import { PersonalImageType } from '../../types';

export const useDocuments = () => {
  const [uploadProgress, setUploadProgress] = useState({
    birthCertificate: 0,
    unofficialNidCard: 0,
  });

  const [documents, setDocuments] = useState<PersonalImageType>({
    unofficialNidCard: null,
    birthCertificate: null,
  });

  useEffect(() => {
    MyAxios.getFile('/api/v1/auth/user/investor/info/docs').then((resp) => {
      // TODO this part is very risky, because we have no typescript
      setDocuments({
        birthCertificate: resp.data.data.identity_doc_temp_address,
        unofficialNidCard: resp.data.data.bank_doc_temp_address,
      });
    });
  }, []);

  const handleOnDocumentChange =
    (docType: 'birthCertificate' | 'unofficialNidCard') => (value: File | null) => {
      const shouldRemoveCurrentDocument = !value;

      if (shouldRemoveCurrentDocument) setDocuments((images) => ({ ...images, [docType]: null }));
      else {
        const formData = new FormData();
        formData.append('image', value);
        MyAxios.uploadFormData('/api/v1/auth/user/investor/auth/notinstant-identitydocs', formData, {
          params: {
            docType: docType === 'birthCertificate' ? 'identityDoc' : 'bankDoc',
          },
          onUploadProgress: (ev) =>
            setUploadProgress((state) => ({
              ...state,
              [docType]: Math.round((ev.loaded * 100) / (ev.total ?? 1)),
            })),
        }).then((resp) => {
          setDocuments((images) => ({
            ...images,
            [docType]: resp.data.data.temp_address ?? null,
          }));
        });
      }
    };

  return { documents, uploadProgress, handleOnDocumentChange };
};
