import { useMutation, useQuery } from 'react-query';
import { authPostKeygen, authQueryKeygen } from 'src/core/query/en/authClient';

export const usePersonalInfo = () => {
  const { refetch: personalInfoRefetch } = useQuery({
    ...authQueryKeygen('/api/v1/auth/user/investor/info/personal', {
      params: { header: {} },
    }),
  });

  const { mutateAsync: submitPersonalInfo, isLoading: isLoadingPersonalInfoSubmit } = useMutation(
    authPostKeygen('/api/v1/auth/user/investor/auth/notinstant-personalinfo'),
  );

  const setPersonalInfoDefaultValues = async () => {
    return personalInfoRefetch().then(({ data }) => ({
      first_name: data?.data?.first_name ?? 'aa',
      birth_date: new Date(data?.data?.birth_date ?? ''),
      gender: data?.data?.gender ?? 'male',
      last_name: data?.data?.last_name ?? '',
      national_id: data?.data?.national_id ?? '',
    }));
  };

  return {
    setPersonalInfoDefaultValues,
    submitPersonalInfo,
    isLoadingPersonalInfoSubmit,
  };
};
