import { useQuery } from 'react-query';
import { authQueryKeygen } from 'src/core/query/fa/authClient';

export const useGetAuthStatus = () => {
  return useQuery({
    ...authQueryKeygen('/api/v1/auth/user/investor/auth/status', {
      params: { header: {} },
    }),
    enabled: true,
  });
};
