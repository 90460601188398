import { BankModal as EnBankModal } from 'src/shared/components/BankModal/EnBankModal';
import { useDialog } from 'src/shared/hooks';

type PropsType = {
  accountNumber?: string;
  handleOnClose?: () => void;
};
export const useBankDialog = (props: PropsType) => {
  const {
    dialog: { open: bankOpen, onClose: bankOnClose },
    button: { onClick: bankModalOnClick },
  } = useDialog(false);

  const handleOnClose = () => {
    props.handleOnClose?.();
    bankOnClose();
  };

  return {
    data: {
      bankModalOnClick,
    },
    Component: (
      <EnBankModal open={bankOpen} onClose={handleOnClose} accountNumber={props.accountNumber} />
    ),
  };
};
