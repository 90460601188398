import { t } from '@lingui/macro';
import {
  emptyOrNumberRegex,
  enPostalCodeRegex,
  numRegex,
  numberAndAlphabetRegex,
  stringRegex,
} from 'src/shared/regex/textRegex';
import { z } from 'zod';

export const personalInformationSchema = () =>
  z.object({
    first_name: z
      .string()
      .min(1, t`Field is required`)
      .regex(stringRegex, t`Invalid character`),
    last_name: z
      .string()
      .min(1, t`Field is required`)
      .regex(stringRegex, t`Invalid character`),
    national_id: z
      .string()
      .regex(numberAndAlphabetRegex, t`Please enter digits and alphabets`)
      .min(8, t`Enter between 8 and 12 digits and alphabets`)
      .max(12, t`Enter between 8 and 12 digits and alphabets`),
    gender: z.enum(['male', 'female']),
    birth_date: z.date(),
  });

export type PersonalInformationSchemaType = z.infer<ReturnType<typeof personalInformationSchema>>;

export const contactInformationSchema = () =>
  z.object({
    address: z.string().min(1, t`Field is required`),
    city_id: z.number({ required_error: t`Field is required` }),
    country_id: z.number({ required_error: t`Field is required` }),
    email: z
      .string()
      .email(t`Invalid email address`)
      .min(1, t`Field is required`),
    home_phone: z
      .string()
      .regex(emptyOrNumberRegex, t`Please enter digits`)
      .optional(),
    mobile: z.object({
      country_code: z.number({ required_error: t`Field is required` }),
      number: z
        .string()
        .regex(numRegex, t`Please enter digits`)
        .length(10, t`Enter exactly 10 digits`),
    }),
    postal_code: z
      .string()
      .regex(enPostalCodeRegex, t`Invalid character`)
      .length(6, t`Enter exactly 6 or 10 characters`)
      .or(
        z
          .string()
          .regex(enPostalCodeRegex, t`Invalid character`)
          .length(10, t`Enter exactly 6 or 10 characters`),
      ),

    province_id: z.number({ required_error: t`Field is required` }),
  });
export type ContactInformationSchemaType = z.infer<ReturnType<typeof contactInformationSchema>>;

export const verifyContactCodeSchema = () =>
  z.object({
    code: z
      .string()
      .regex(numRegex, t`Please enter digits`)
      .length(5, t`Enter exactly 5 digits`),
  });
export type VerifyContactCodeSchemaType = z.infer<ReturnType<typeof verifyContactCodeSchema>>;
