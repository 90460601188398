import { useMutation, useQuery } from 'react-query';
import { authPostKeygen, authQueryKeygen } from 'src/core/query/en/authClient';

export const useContactInfo = () => {
  const { data: contactInfo, refetch: refetchContactInfo } = useQuery({
    ...authQueryKeygen('/api/v1/auth/user/investor/info/contact', {
      params: { header: {} },
    }),
  });

  const { mutateAsync: submitContactInfo, isLoading: isLoadingSubmittingContactInfo } = useMutation(
    authPostKeygen('/api/v1/auth/user/investor/auth/contactinfo'),
  );

  return { contactInfo, refetchContactInfo, submitContactInfo, isLoadingSubmittingContactInfo };
};
