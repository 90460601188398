import { useQuery } from 'react-query';
import { authQueryKeygen } from 'src/core/query/en/authClient';

type PropsType = {
  countryId: number;
  provinceId: number;
};
export const useFormData = (props: PropsType) => {
  const { data: phoneCodes } = useQuery({
    ...authQueryKeygen('/api/v1/auth/phone-codes', {
      params: { header: {} },
    }),
  });

  const { data: countryData } = useQuery({
    ...authQueryKeygen('/api/v1/auth/country', {
      params: { header: {} },
    }),
    enabled: true,
  });
  const { data: provinceData } = useQuery({
    ...authQueryKeygen('/api/v1/auth/province', {
      params: { header: {}, query: { countryId: Number(props.countryId) ?? 0 } },
    }),
    enabled: !!props.countryId,
  });
  const { data: cityData } = useQuery({
    ...authQueryKeygen('/api/v1/auth/city', {
      params: { header: {}, query: { provinceId: Number(props.provinceId) ?? 0 } },
    }),
    enabled: !!props.provinceId,
  });
  return {
    countryData,
    provinceData,
    cityData,
    phoneCodes,
  };
};
