import { useMutation, useQuery } from 'react-query';
import { authPatchKeygen, authQueryKeygen } from 'src/core/query/en/authClient';

export const useBankInfo = () => {
  const { data: bankInfo, refetch: bankInfoRefetch } = useQuery({
    ...authQueryKeygen('/api/v1/auth/user/investor/bank', {
      params: { header: {} },
    }),
  });

  const { mutateAsync: submitAuthForm, isLoading: isLoadingAuthForm } = useMutation(
    authPatchKeygen('/api/v1/auth/user/investor/auth/subbmit'),
  );

  const { mutateAsync: deleteCardNumber } = useMutation(
    authPatchKeygen('/api/v1/auth/user/investor/bank'),
  );
  const handleDelete = () => {
    bankInfo?.data?.accounts[0]?.account_number &&
      deleteCardNumber({
        body: {
          account_number: bankInfo?.data?.accounts[0].account_number,
          card_number: bankInfo?.data?.accounts[0].card_number ?? '',
        },
        params: {},
      }).then(() => bankInfoRefetch());
  };

  return {
    bankInfo,
    bankInfoRefetch,
    submitAuthForm,
    isLoadingAuthForm,
    handleDelete,
  };
};
