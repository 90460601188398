import { t } from '@lingui/macro';
import { SuccessModal } from 'src/shared/components/SuccessModal';
import { useDialog } from 'src/shared/hooks';

export const useSuccessDialog = () => {
  const {
    dialog: { open: successOpen, onClose: successOnClose },
    button: { onClick: successModalOnClick },
  } = useDialog(false);

  return {
    data: {
      successModalOnClick,
    },
    Component: (
      <SuccessModal
        open={successOpen}
        onClose={successOnClose}
        title={t`Pending`}
        body={t`Authentication is in review`}
        buttonCaption={t`Done`}
      />
    ),
  };
};
