import { Trans } from '@lingui/macro';

export const warnings = [
  <Trans>
    To secure the platform and safeguard user interests, please submit the following documents:
    <br />
    <em>Identity Verification:</em> 'Driving License' and at least one of these: 'Certificate of
    Citizenship', 'Permanent Resident' or 'Passport'
    <br />
    <em>Bank Verification:</em> 'Bank Void' or recent 'Bank Statement'
    <br />
    Ensure clarity and legibility in provided information. Combine and upload multiple documents in
    a single image or PDF file.
    <br />
    In case of difficulties or for assistance, submit a ticket in the Support section.
    <br />
    <em>Note:</em> Offline identity verification may take up to 2 business days.
  </Trans>,
];
